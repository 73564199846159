<template>
  <div
    class="cursor-pointer rounded-3xl bg-white px-4 py-6 transition-all md:px-6 lg:px-6 lg:py-8"
    @click="active = !active"
  >
    <div class="relative">
      <div class="pr-11 text-xl font-bold md:pr-0">
        <slot name="header"></slot>
      </div>

      <div class="absolute right-0 top-0 flex h-full cursor-pointer items-center justify-center">
        <div
          class="duration-400 flex h-6 w-6 transform items-center justify-center rounded-full transition-all xl:h-8 xl:w-8"
          :class="[active ? 'rotate-0 bg-dnd_yellow' : 'rotate-45 bg-dnd_yellow']"
        >
          <img
            src="assets/icons/close.svg"
            class="pointer-events-none h-2 w-2 select-none object-contain xl:h-3 xl:w-3"
            alt="close"
          />
        </div>
      </div>
    </div>
    <div
      class="duration-400 overflow-hidden transition-[max-height]"
      :class="[active ? 'max-h-140' : 'max-h-0']"
    >
      <div class="flex flex-col gap-4 pt-4">
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: () => ({
      header: 'Как работают займы в интернете',
      description:
        'Выберите комфортную для себя сумму и отправьте нам заявку через интернет. В анкете укажите основную информацию о себе — ФИО, регион проживания, источники дохода. После успешного одобрения заявленная сумма поступает на вашу банковскую карту. Для обработки заявки используем современные технологии, чтобы сделать процесс максимально быстрым и дать ответ в кратчайшие сроки. Уже сегодня вы можете занять денег и получить их сразу на счет. Основное условие — чтобы счет был открыт в российском банке. Для оформления подойдут карты любых платежных систем:',
    }),
  },
});

const active = ref(false);
</script>
