<template>
  <main class="flex flex-col gap-10 bg-dnd_white lg:gap-20">
    <div class="flex flex-col gap-4 rounded-b-3xl max-lg:bg-dnd_light_grey max-lg:p-4">
      <div class="flex flex-col gap-4">
        <CardSpecialOffer class="lg:hidden" />
        <HeroScreen />
      </div>
    </div>

    <div class="customContainer flex-col gap-4 px-4">
      <h2 class="text-2xl font-bold xl:text-[40px]">Какие у нас займы?</h2>
      <section class="mt-6 flex flex-col gap-4 lg:w-full lg:flex-row xl:mt-10">
        <CardInformational
          v-for="(item, index) in loanData"
          :key="index"
          class="h-full w-full"
          :loan-data="item"
        />
      </section>
    </div>

    <div class="customContainer px-4">
      <CardMotivation />
    </div>

    <section class="customContainer flex flex-col px-4">
      <div class="mb-6 h-full text-2xl font-bold xl:mb-10 xl:text-[40px]">
        <h2>Как получить займ?</h2>
      </div>
      <LazyCarouselLoanSteps />
    </section>

    <div class="flex h-auto flex-col rounded-3xl bg-dnd_light_grey">
      <div class="customContainer px-4">
        <div class="flex flex-col gap-y-10 py-10 xl:gap-y-20 xl:py-20">
          <LazyCarouselBlog />
          <LazyCarouselReview />
        </div>
      </div>
    </div>

    <section class="customContainer">
      <div class="px-4 pb-4 lg:px-0">
        <div class="text-2xl font-bold">
          <div class="block pb-6 font-semibold lg:text-[40px] xl:block xl:pb-10 5xl:px-0">
            <h2 class="font-bold xl:text-[40px] xl:leading-10">
              Как получить срочный
              <br />
              микрозайм онлайн на карту
            </h2>
          </div>
        </div>
        <div class="rounded-3xl bg-dnd_light_grey px-4 py-6 lg:rounded-[40px] xl:p-10 2xl:pb-10">
          <div class="flex flex-col gap-y-4">
            <DropDownInfo>
              <template #header>
                <p class="text-base leading-5 xl:text-2xl">
                  Как заполнить онлайн заявку на микрозаем
                </p>
              </template>
              <template #description>
                <p class="font-manrope text-sm font-medium xl:text-xl">
                  Если у вас уже есть аккаунт, войдите, используя свои учетные данные. Если нет —
                  заполните специальную форму на сайте. Для подтверждения регистрации введите код из
                  СМС-сообщения.
                </p>
              </template>
            </DropDownInfo>
            <DropDownInfo>
              <template #header>
                <p class="text-base leading-5 xl:text-2xl">
                  Возможности микрозайма без кредитной истории
                </p>
              </template>
              <template #description>
                <p class="font-manrope text-sm font-medium xl:text-xl">
                  Из-за проблемной кредитной истории или ее отсутствия крайне сложно получить кредит
                  в банке. Микрозаем — отличный выход в такой ситуации.
                </p>
                <div>
                  <h3 class="text-lg font-semibold">
                    Как получить займ без проверки кредитной истории
                  </h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    Оформите заявку онлайн на сайте или свяжитесь с нами по телефону. Плохая или
                    нулевая кредитная история — не помеха.
                  </p>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">Как использовать калькулятор займа</h3>
                  <ul class="font-manrope text-sm font-medium xl:text-xl">
                    <li>Отметьте сумму, которую планируете взять.</li>
                    <li>Укажите срок возврата долга.</li>
                    <li>Получите информацию о процентах и переплате по микрокредиту.</li>
                  </ul>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">Подробный расчет стоимости займа</h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    Онлайн калькулятор сделает точный расчет по выбранным критериям, автоматически
                    определит период начисления процентов и итоговую сумму. Поможет подобрать
                    наиболее выгодный срок микрозайма.
                  </p>
                </div>
              </template>
            </DropDownInfo>
            <DropDownInfo>
              <template #header>
                <p class="text-base leading-5 xl:text-2xl">
                  Быстрый займ на карту: рекомендации по использованию
                </p>
              </template>
              <template #description>
                <p class="font-manrope text-sm font-medium xl:text-xl">
                  Деньги нецелевые, их можно тратить на свое усмотрение — покупку нужных вещей,
                  лечение, отпуск. Возникли непредвиденные расходы? Нужно отдать долг? Оплатить
                  коммунальные услуги? Микрокредит — быстрое решение финансовых проблем.
                </p>
                <div>
                  <h3 class="text-lg font-semibold">Сроки получения срочного займа</h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    В большинстве случаев деньги поступают на карту в течение часа после одобрения
                    заявки в первый раз. Постоянным клиентам — еще быстрее.
                  </p>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">Как эффективно использовать быстрый займ</h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    Вносите платежи в срок, это положительно отражается на кредитной истории.
                    Добросовестные заемщики получают выгодные предложения и берут ссуду под более
                    низкие проценты.
                  </p>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">Сроки и условия погашения микрозайма</h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    Микрокредит можно оформить на срок от 2 до 26 недель. Первый раз ссуда
                    оформляется на минимальный срок — 14 дней, под 0%. Возвращайте долг вовремя и
                    пользуйтесь деньгами бесплатно.
                  </p>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">Как выбрать оптимальный период для займа</h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    Исходите из своей ситуации — если нужно занять несколько тысяч до зарплаты,
                    выбирайте минимальный срок. Если требуется серьезная сумма, рассмотрите более
                    длительный период с комфортным графиком платежей.
                  </p>
                </div>
                <div>
                  <h3 class="text-lg font-semibold">
                    Рекомендации по принятию решения о взятии займа
                  </h3>
                  <p class="font-manrope text-sm font-medium xl:text-xl">
                    Берите микрокредит в случае конкретной потребности. Тогда он станет отличным
                    финансовым инструментом — всегда под рукой, без необходимости занимать деньги у
                    друзей и знакомых.
                  </p>
                </div>
              </template>
            </DropDownInfo>
          </div>
          <p class="pt-6 font-manrope text-sm font-bold xl:pb-0 xl:text-xl">
            Выдаем займы практически без отказа. Вы можете взять в долг любую нужную сумму до 100000
            рублей. Деньги поступают на банковскую карту. Обязательное условие — карта должна быть
            открыта в российском банке на самого заемщика.
          </p>
        </div>
      </div>
      <div class="px-4 lg:px-0">
        <div
          class="flex flex-col gap-y-4 rounded-3xl bg-dnd_light_grey px-4 pb-6 pt-6 lg:rounded-[40px] xl:px-10 xl:py-10"
        >
          <div class="text-2xl font-bold">
            <h2
              class="block w-[80%] font-bold leading-7 lg:w-auto lg:pb-0 lg:leading-tight xl:text-[40px]"
            >
              <p>Преимущества займов на карту банка</p>
            </h2>
          </div>
          <div class="w-full lg:px-0 2xl:pt-4">
            <div class="grid-cols-news grid gap-4 xl:w-full">
              <div
                v-for="(item, i) in bestLoans"
                :key="i"
                class="shadows flex flex-shrink flex-col gap-2 rounded-3xl bg-white px-4 py-6 lg:px-6 lg:py-8 xl:h-[129px] xl:flex-row"
              >
                <div class="flex items-center gap-3">
                  <div
                    class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-dnd_light_purple font-manrope text-xs font-semibold xl:h-[48px] xl:w-[48px] xl:text-xl"
                  >
                    {{ i + 1 }}
                  </div>
                  <div class="text-base font-bold xl:flex xl:flex-col xl:gap-2 xl:text-2xl">
                    <h2>
                      {{ item.header }}
                    </h2>
                    <div
                      class="hidden font-manrope text-dnd_dark_grey xl:flex xl:text-xl xl:font-semibold"
                    >
                      <p>
                        {{ item.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="block pl-10 font-manrope text-sm leading-5 text-dnd_dark_grey xl:hidden xl:text-xl xl:font-semibold"
                >
                  <p>
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
const loanData = [
  {
    advantages: [
      'Удобно на длинный срок',
      'Честное полное и частичное досрочное гашение на любом сроке',
      'Повторным клиентам сумма больше, а процент ниже',
    ],
    title: 'Стандартный',
    loanSumStart: 5000,
    loanSumEnd: 50000,
    comments: '',
    svg: 'site_money.svg',
  },
  {
    advantages: [
      'Доступен при повторном обращении',
      'Снижен процент',
      'Увеличена максимально допустимая сумма',
      'Упрощенная проверка и верификация',
    ],
    title: 'Стандартный Клуб',
    loanSumStart: 5000,
    loanSumEnd: 100000,
    comments: '',
    svg: 'site_banknotes.svg',
  },
  {
    advantages: [
      'В течение 14 дней можно вернуть только сумму займа, без процентов',
      'Досрочное погашение также доступно на любом сроке займа',
      'Специальные условия возврата',
    ],
    title: 'Акция для новых клиентов',
    loanSumStart: 5000,
    loanSumEnd: 100000,
    svg: 'site_percent.svg',
    comments:
      'Если деньги в этот срок вернуть не получается, продолжите пользоваться займом под минимальную процентную ставку, еженедельно внося небольшие платежи в течение 26 недель',
  },
];

const bestLoans = [
  {
    header: 'Скорость',
    description: 'От оформления заявки до денег на карте считанные минуты.',
  },
  {
    header: 'Минимальные требования',
    description:
      'Отсутствие официального дохода, проблемы с кредитной историей — микрозаймы доступны всем.',
  },
  {
    header: 'Прозрачные условия',
    description: 'Нет скрытых комиссий и платежей, в договоре расписаны суммы выплат по графику.',
  },
  {
    header: 'Досрочный возврат',
    description: 'Долг можно погасить досрочно или продлить без штрафных санкций.',
  },
  {
    header: 'Работа 24/7',
    description: 'Вы можете оформить срочный микрозайм в любое время суток, даже ночью.',
  },
];
</script>
