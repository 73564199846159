<template>
  <div class="rounded-lg bg-[#6C5FFA] text-dnd_white md:relative md:overflow-hidden">
    <div class="flex flex-row">
      <div class="flex w-[75%] flex-col gap-y-2 py-6 pl-4">
        <p class="font-semibold leading-tight">Первый онлайн заём бесплатно*</p>
        <div class="flex flex-col gap-0 font-manrope text-xs font-semibold md:flex-row">
          <p>
            *-До 50 000 рублей
            <span class="block md:hidden">на 14 дней</span>
            <span class="hidden md:inline">&nbsp;на 14 дней</span>
          </p>
        </div>
      </div>
      <div class="flex flex-grow items-end">
        <NuxtImg
          src="/images/Phone1.png"
          alt="Телефон"
          class="pointer-events-none h-[120px] w-[230px] select-none xxxs:h-[110px] xxxs:w-[170px] md:hidden md:h-[90px]"
          preset="sampleOptimized"
          sizes="170px"
        />
        <LazyNuxtImg
          src="/images/PhoneTablet.png"
          alt="Телефон"
          class="pointer-events-none hidden select-none md:absolute md:block md:h-[145px] md:-translate-x-4 md:translate-y-8"
          preset="sampleOptimized"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>
