<template>
  <div
    class="w-full overflow-hidden lg:relative lg:z-0 lg:mb-[-40px] lg:flex lg:h-[672px] lg:-translate-y-10 lg:items-center lg:justify-between lg:bg-custom-gradient lg:pt-10 xl:rounded-b-4xl"
  >
    <div class="customContainer mx-auto w-full lg:container">
      <div class="flex w-full items-center justify-between">
        <div class="flex w-2/3 flex-col gap-8 font-bold text-white max-lg:hidden 3xl:w-1/3">
          <div class="h-[63px] w-[323px] rounded-br-4xl rounded-tl-4xl bg-white p-4 text-black">
            <p
              class="whitespace-nowrap bg-gradient-to-r from-indigo-600 to-violet-500 bg-clip-text font-manrope text-2xl text-transparent"
            >
              Деньги у вас уже в {{ futureTime }}
            </p>
          </div>
          <div class="item-center flex">
            <h1 class="text-[56px] leading-[60px]">Первый онлайн заём бесплатно*</h1>
          </div>

          <div class="z-10 font-manrope text-2xl font-semibold">*-До 50 000 рублей на 14 дней</div>
        </div>
        <div class="relative hidden h-full w-5/12 3xl:block">
          <LazyNuxtImg
            class="pointer-events-none absolute max-h-[600px] max-w-[646px] -translate-x-[80px] -translate-y-[245px] select-none 5xl:-translate-x-[30px]"
            src="/images/HeroscreenGirl.png"
            alt="Girl"
            preset="sampleOptimized"
            loading="lazy"
            width="646"
            height="564"
          />
        </div>
        <div class="flex w-2/4 justify-end max-lg:w-full 3xl:w-2/5">
          <LoanCalculator class="w-full lg:h-[440px] lg:w-[500px] xl:h-[490px] 3xl:w-[547px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LoanCalculator from '@/components/LoanCalculator.vue';
import dayjs from 'dayjs';

const futureTime = ref('');

onMounted(() => {
  futureTime.value = dayjs().add(15, 'minute').format('HH:mm');
});
</script>
