<template>
  <div
    class="w-400 flex flex-col rounded-3xl bg-dnd_light_grey lg:h-[570px] lg:rounded-[40px] xl:h-[700px] xl:rounded-4xl 2xl:h-[670px] 3xl:h-[590px] 5xl:h-[577px]"
  >
    <div class="flex flex-row items-center justify-between p-4 xl:p-6">
      <div class="text-xl font-bold xl:text-[26px] 3xl:text-[28px]">
        <p class="leading-6 xl:leading-9">
          Займ
          <br />
          «{{ loanData.title }}»
        </p>
      </div>
      <NuxtImg
        :src="`icons/${loanData.svg}`"
        class="pointer-events-none w-[90px] select-none"
        alt="Виды займов"
        width="90"
        height="105"
      />
    </div>
    <div
      class="m-0.5 flex h-full flex-col rounded-2xl bg-dnd_white md:mx-4 lg:m-0.5 xl:rounded-4xl"
    >
      <div
        class="flex flex-row gap-1.5 px-4 py-6 lg:flex-col xl:w-full xl:p-6 3xl:flex-row 3xl:py-8"
      >
        <div
          class="inline-flex min-w-0 max-w-max rounded-2xl bg-dnd_light_purple px-3 py-2 font-manrope text-xs font-semibold xl:flex xl:items-center xl:justify-center xl:text-base xl:font-semibold"
        >
          <div>От {{ formattedLoanSumStart }} ₽ до {{ formattedLoanSumEnd }} ₽</div>
        </div>
        <div
          class="inline-flex min-w-0 max-w-max rounded-2xl bg-dnd_light_purple px-3 py-2 text-xs font-bold lg:flex lg:flex-row lg:gap-1"
        >
          <div class="font-manrope text-xs font-semibold xl:text-base">От 9 до 26 недель</div>
          <div class="group relative hidden lg:flex xl:items-center xl:justify-center">
            <div
              v-if="loanData.comments"
              class="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-dnd_yellow"
            >
              !
            </div>
            <div
              class="absolute -left-28 bottom-full mb-4 hidden w-80 -translate-x-1/2 transform rounded-3xl border-2 border-gray-100 bg-white p-4 text-center font-manrope text-sm font-semibold text-black group-hover:flex xl:left-1/2"
            >
              {{ loanData.comments }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 pb-6">
        <div
          v-for="(advantage, index) in loanData.advantages"
          :key="index"
          class="flex flex-col justify-between px-4 text-sm xl:gap-y-0 xl:px-6 xl:py-0"
        >
          <div class="flex flex-row items-center gap-[12px]">
            <div>
              <div
                class="flex h-6 w-6 items-center justify-center rounded-full bg-dnd_light_purple xl:h-8 xl:w-8"
              >
                {{ ++index }}
              </div>
            </div>
            <div
              class="font-manrope text-sm font-semibold xl:h-full xl:items-center xl:justify-center xl:text-lg xl:font-semibold"
            >
              {{ advantage }}
            </div>
          </div>
        </div>
        <div
          v-if="loanData.comments != ''"
          class="block px-4 lg:hidden"
        >
          <div class="rounded-2xl bg-dnd_light_grey">
            <div class="p-4 font-manrope text-sm font-semibold">
              {{ loanData.comments }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-auto px-4 pb-6 xl:px-6 xl:pb-8">
        <NuxtLink
          to="/vzyat-v-dolg"
          class="block"
        >
          <UiButton
            small
            class="!min-h-[48px] w-full !rounded-lg bg-dnd_yellow !text-sm lg:min-h-[64px] lg:!rounded-[20px] xl:!text-[18px] xl:text-lg"
          >
            Оформить займ
          </UiButton>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  loanData: {
    type: Object,
    required: true,
  },
});

const formattedLoanSumStart = computed(() => formatNumber(props.loanData.loanSumStart));
const formattedLoanSumEnd = computed(() => formatNumber(props.loanData.loanSumEnd));

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
</script>
